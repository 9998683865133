<template>
  <v-card>
    <div v-if="showEmailType">
      <v-card-title>
        Enviar E-mail
      </v-card-title>
      <v-card-text>
        <v-col>
          <v-select
            v-model="emailType"
            :items="emailTypes"
             @change="changeEmailType(emailType)"
            label="Tipo de e-mail *"
            :rules="[rules.required]"
          ></v-select>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col style="text-align: end;">
          <v-btn color="green darken-1" text @click="close()">
            Fechar
          </v-btn>
        </v-col>
      </v-card-actions>
    </div>
    <div v-if="showTechnical">
      <TechTeam :items="items" :toggle="close" />
    </div>
    <div v-if="showPrimecare">
      <PrimeCare :type="emailType" :items="items" :toggle="close" />
    </div>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import rules from "@/utils/rules";
import { emailTypes } from "@/utils/dps.utils";

export default {
  name: "Email",
  props: ["toggle", "items"],
  components: {
    TechTeam: () => import("./TechTeam/TechTeam.vue"),
    PrimeCare: () => import("./PrimeCare/PrimeCare.vue")
  },
  mounted() {
    if (this.isComercialTeam()) {
      this.showTechnical = true;
    }
  },
  data() {
    return {
      rules,
      emailType: "",
      showTechnical: false,
      showPrimecare: false
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    emailTypes() {
      return emailTypes
    },
    showEmailType() {
      return !this.isComercialTeam() && !this.showTechnical && !this.showPrimecare
    },
  },
  methods: {
    isComercialTeam() {
      return !this.$store.state.user.isHealthTeam && !this.$store.state.user.isOnboardingTeam;
    },
    close() {
      if (this.toggle) this.toggle();
      Object.assign(this.$data, this.$options.data.apply(this));
      this.showTechnical = this.isComercialTeam()
    },
    changeEmailType(emailType) {
      this.showTechnical = (emailType === 'technical')
      this.showPrimecare = (emailType === 'primecare' || emailType === 'cpt')
    },
  }
};
</script>
<style scoped>
.selection {
  display: flex;
}
</style>
